import React from 'react';
import {
  ChakraProvider,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Badge,
  StarIcon,
  Link,
} from '@chakra-ui/react';

export default function NameSpelling() {
  return (
    <Tabs size='md' variant='enclosed'>
    <TabList>
      <Tab _selected={{ color: '#52052e', bg: '#dde2ff' }}>One</Tab>
      <Tab _selected={{ color: '#52052e', bg: '#dde2ff' }}>Two</Tab>
      <Tab _selected={{ color: '#52052e', bg: '#dde2ff' }}>Three</Tab>
      <Tab _selected={{ color: '#52052e', bg: '#dde2ff' }}>Four</Tab>
    </TabList>
<TabPanels bg='#FFFFFF' borderWidth={10} borderColor='#52052e' border='10' borderRadius={10} textAlign='center' onTransitionEnd={8}>
      <TabPanel >
        <p>one! : name origin 🌱</p>
        
        First of all, I appreciate your effort, learning to correctly pronounce 
        someone's name is always a good thing. My name is originated from 
        Mongolia 🇲🇳  Fun fact: A lot of the Mongolian names are long and hard to pronounce 
        pronounce for non-native speakers, and contrary to what you may be thinking, my name is not
        rare at all. In fact it's one of the most popular names in Mongolia!
        Now, without wasting more of your, let's dive into how to actually pronounce my name!
      </TabPanel>
      <TabPanel>
        <p>two! : TLDR : kh is silent</p>
        So, I know it looks super complicated but it's actually not that hard. To make it simpler for you,
        consider the "KH" letters silent. Therefore, it becomes lagvasuren, which sounds like 
        <b> la-vag-soo-ren</b>. EZPZ 😎 Now, you can basically pronounce it like a native!
      </TabPanel>
      <TabPanel >
        <p>three: If it's silent, then why is there?😲  </p>
        Well, it's a long story, but basically, there are 35 alphabet letters in the Mongolian language,and some of them simply needs multiple letters 
        to be written in english (like the "LKH" letters). That means, LKH is just another way to pronounce L, your tongue shouldn't be touching your teeth 
        when you say it. So, when you see the "LKH" letters (which seems illegal, I know), just consider it as  a little angry "L" and you're good to go! 😉
      </TabPanel>
      <TabPanel>
        <p>four: some more resources if you're interested 📚</p>
        Well, congratulations🎉! You've mastered the pronunciation of a pretty complicated and 
        tricky name! Some letters just disappeared, and some of them exchanged places when you pronounce it 😳 
        What's the meaning of it? I don't know and to my best knowledge, it's simply just a name. Now, if you're interested in learning more about the 
        name, here's some of the resources that I found that might be more helpful:
        <ol>
        <a to="https://www.names.org/n/lkhagvasuren/about#pronunciation">names.org</a> <br/>
        <a to="https://en.wikipedia.org/wiki/Mongolian_name">Wiki page about Mongolian names</a> 
        </ol>
      </TabPanel>
    </TabPanels>
  </Tabs>
  );
}
