import React from 'react'
import Layout from '../components/layout'
import NameSpelling from '../components/NameSpelling'
import * as styles from "../components/index.module.css"
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const forfun = () => (
<>
    <Layout>
   <h3 >Four steps to pronounce "LKHAGVASUREN"</h3>
    <NameSpelling/>
    </Layout>
    <div className={styles.textCenter}>
      <StaticImage
        src="../images/cutie.png"
        loading="eager"
        width={250}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt=""
        style={{ marginBottom: 0 }}
      />
      </div>
      <br/>
      <br/>
      <Link to="/">Go back to the homepage</Link>
      </>
  )


export default forfun
